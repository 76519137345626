export const coordValues = [
  {
    lat: 38.98695,
    lng: -94.61968,
    city: "Overland Park",
    prov: "Kansas",
    state: "United States",
    name: "Meadow Lake Country Club",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: -43.89834,
    lng: 171.70011,
    city: "Ashburton",
    prov: "Canterbury",
    state: "New Zealand",
    name: "Leafield",
    continent: "Oceania",
    strCoords: "",
  },
  {
    lat: 64.87137,
    lng: 26.72718,
    city: "Juntunen",
    prov: "Northern Ostrobothnia",
    state: "Finland",
    name: "Musta",
    continent: "Europe",
    strCoords: "",
  },
  {
    lat: 46.98999,
    lng: -107.83178,
    city: "Mosby",
    prov: "Montana",
    state: "United States",
    name: "North Fork Sage Hen Creek",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 42.21667,
    lng: 102.06667,
    city: "Elst Hötöliyn Ovoo",
    prov: "Ömnögovĭ",
    state: "Mongolia",
    name: "",
    continent: "Asia",
    strCoords: "",
  },
  {
    lat: -22.95,
    lng: 115.883333,
    city: "Mount Florry",
    prov: "",
    state: "Australia",
    name: "",
    continent: "Oceania",
    strCoords: "",
  },
  {
    lat: 34.2,
    lng: 37.36667,
    city: "Jabal Ru’ūs",
    prov: "Homs",
    state: "Syria",
    name: "ناحية القريتين (Al-Qaryatayn Subdistrict)",
    continent: "Middle East",
    strCoords: "",
  },
  {
    lat: 31.48038,
    lng: -112.84932,
    city: "Jabal Ru’ūs",
    prov: "Sonora",
    state: "Mexico",
    name: "Cerro El Tullido",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 12.72261,
    lng: 106.00555,
    city: "Char Tnaol",
    prov: "Kratie",
    state: "Cambodia",
    name: "Char Tnaol",
    continent: "Asia",
    strCoords: "",
  },
  {
    lat: 16.72226,
    lng: 76.11456,
    city: "Bhairvadgi",
    prov: "Karnataka",
    state: "India",
    name: "Bhairvadgi",
    continent: "Asia",
    strCoords: "",
  },
  {
    lat: -32.88786,
    lng: 134.06667,
    city: "Westall",
    prov: "South Australia",
    state: "Australia",
    name: "Point Westall",
    continent: "Oceania",
    strCoords: "",
  },
  {
    lat: -1.90898,
    lng: -60.06045,
    city: "Sítio Selva de Pedra",
    prov: "Amazonas",
    state: "Brazil",
    name: "Fazenda Círculo Gaúcho",
    continent: "South America",
    strCoords: "",
  },

  {
    lat: -16.65,
    lng: -58.483333,
    city: "El Vi",
    prov: "Santa Cruz",
    state: "Bolivia",
    name: "Córrego Morro Branco",
    continent: "South America",
    strCoords: "",
  },
  // {
  //   lat: 52.75,
  //   lng: 101.33333,
  //   city: "Dayalyk",
  //   prov: "Buryatiya Republic",
  //   state: "Russia",
  //   name: "",
  //   strCoords: "",
  // },
  {
    lat: -12.33333,
    lng: -38.55,
    city: "Sangradouro",
    prov: "Bahia",
    state: "Brazil",
    name: "Sangradouro",
    continent: "South America",
    strCoords: "",
  },
  {
    lat: 55.14191,
    lng: -110.74348,
    city: "Lakeland County",
    prov: "Alberta",
    state: "Canada",
    name: "",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 66.51091,
    lng: -115.66869,
    city: "Akaitcho Lake",
    prov: "Nunavut",
    state: "Canada",
    name: "",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 34.80387,
    lng: -118.12702,
    city: "Rosamond",
    prov: "California",
    state: "United States",
    name: "Rainbow Farms (historical)",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 17.9521,
    lng: 37.598,
    city: "Jibāl Wershiqanī",
    prov: "Red Sea",
    state: "Sudan",
    name: "",
    continent: "Africa",
    strCoords: "",
  },
  {
    lat: 5.16667,
    lng: 37.9,
    city: "Luk’aluk’ē Bota",
    prov: "Oromiya",
    state: "Ethiopia",
    name: "",
    continent: "Middle East",
    strCoords: "",
  },
  {
    lat: 12.68,
    lng: 54.12197,
    city: "",
    prov: "Soqatra",
    state: "Yemen",
    name: "",
    continent: "Middle East",
    strCoords: "",
  },
  {
    lat: 42.18333,
    lng: 53.66667,
    city: "Chagali",
    prov: "",
    state: "Turkmenistan",
    name: "Chagali",
    continent: "Asia",
    strCoords: "",
  },
  {
    lat: 68.5,
    lng: -132.666667,
    city: "Sitidgi Lake",
    prov: "Inuvik",
    state: "Canada",
    name: "",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: 54.68841,
    lng: 110.81929,
    city: "Alla",
    prov: "Buryatiya Republic",
    state: "Russia",
    name: "Alla",
    continent: "Europe",
    strCoords: "",
  },
  {
    lat: 49.78934,
    lng: 73.13738,
    city: "Karagandy",
    prov: "Karaganda",
    state: "Kazakhstan",
    name: "Malaya Bukpa",
    continent: "Asia",
    strCoords: "",
  },
  {
    lat: 32.85816,
    lng: -109.45361,
    city: "Topaz Tank",
    prov: "Arizona",
    state: "United States",
    name: "",
    continent: "North America",
    strCoords: "",
  },
  {
    lat: -52.82152,
    lng: -72.90381,
    city: "Isla Chandler",
    prov: "Region of Magallanes",
    state: "Chile",
    name: "",
    continent: "South America",
    strCoords: "",
  },
  {
    lat: -22.196389,
    lng: 35.251667,
    city: "Cabo Murriane",
    prov: "Inhambane",
    state: "Mozambique",
    name: "Lago Nhachinhate",
    continent: "Africa",
    strCoords: "",
  },
  {
    lat: 50.66667,
    lng: 6.03333,
    city: "Lontzen",
    prov: "",
    state: "Belgium",
    name: "",
    continent: "Europe",
    strCoords: "",
  },
  {
    lat: -19.0317,
    lng: 28.78076,
    city: "",
    prov: "Matabeleland North",
    state: "Zimbabwe",
    name: "",
    continent: "Africa",
    strCoords: "",
  },
];

// export default function todayCoords() {
//   const start = new Date("2022 10 3");
//   const now = Date.now();
//   const nDays = Math.floor((now - start) / (60 * 60 * 1000 * 24));
//   return coordValues[coordValues.length % nDays];
// }
